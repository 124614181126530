@tailwind base;
@tailwind components;
@tailwind utilities;

#burger-menu {
  cursor: pointer;
  height: 27px;
  width: 27px;
  margin: 5px;
  margin-left: 1rem;
  overflow: visible;
  position: relative;
  z-index: 2;
}
#burger-menu span,
#burger-menu span:before,
#burger-menu span:after {
  background: #fff;
  display: block;
  height: 2px;
  opacity: 1;
  position: absolute;
  transition: 0.3s ease-in-out;
}
#burger-menu span:before,
#burger-menu span:after {
  content: "";
}
#burger-menu span:before {
  left: 0px;
  top: -10px;
  width: 27px;
}
#burger-menu span {
  right: 0px;
  top: 13px;
  width: 27px;
}
#burger-menu span:after {
  left: 0px;
  top: 10px;
  width: 27px;
}
#burger-menu.close span:before {
  top: 0px;
  transform: rotate(90deg);
  width: 27px;
}
#burger-menu.close span {
  transform: rotate(-45deg);
  top: 13px;
  width: 27px;
}
#burger-menu.close span:after {
  top: 0px;
  left: 0;
  transform: rotate(90deg);
  opacity: 0;
  width: 0;
}

#menu {
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  padding-top: 20px;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
#menu.overlay {
  visibility: visible;
  opacity: 1;
  background: #27272a
}
#menu ul {
  padding: 0;
}
#menu li {
  list-style: none;
}
#menu a {
  color: #fff;
  display: block;
  margin-bottom: 50px;
  text-decoration: none;
  @apply text-2xl;
}