@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  @apply bg-zinc-800;
  color: #fff;
  font-weight: 400;
  font-family: "JetBrains Mono", monospace;
}

h1,
h2,
h3 {
  @apply text-3xl font-normal	;
  font-family: "JetBrains Mono", monospace;
  margin-block-start: initial;
  margin-block-end: initial;
  margin-inline-start: initial;
  margin-inline-end: initial;
}

/* === === === COMPONENTS === === === */

@layer components {
  .website--link {
    @apply flex flex-col items-center mb-7 rounded-lg md:flex-row bg-zinc-600;
    opacity: 0;
    transform: translateY(-35px);
  }

  .website--link__image {
    @apply md:my-0 p-4 md:w-40 md:h-24 rounded-t-lg md:rounded-none md:rounded-l-lg object-contain;
    max-width: 8rem;
    max-height: 6rem;
  }
}

/* === === === NAVBAR HOVER FIX === === === */

.dashboard--navbar div div:nth-child(1) {
  @apply md:rounded-t-lg;
}

.dashboard--navbar div div:last-child {
  @apply md:rounded-b-lg;
}

/* === === === DASHBOARD LIST SCROLL BAR CUSTOM === === === */

.dashboard--list {
  scrollbar-gutter: stable;
}

.dashboard--list::-webkit-scrollbar {
  width: 10px;
  border-radius: 25px !important;
}

.dashboard--list::-webkit-scrollbar-track {
  @apply bg-zinc-600 rounded-tr-lg rounded-lg;
}

.dashboard--list::-webkit-scrollbar-thumb {
  @apply bg-blue-500 rounded-tr-lg rounded-lg;
}

/* === === === INDEX  === === === */

.Typewriter {
  font-size: 2.8rem;
  @apply px-4 md:px-0 sm:text-5xl md:text-7xl md:text-left lg:text-8xl lg:text-center text-blue-500;
}

@media screen and (max-width: 640px) {
  .Typewriter-line {
    line-height: 85px !important;
  }
}

@media screen and (min-width: 640px) {
  .Typewriter-line {
    line-height: 135px !important;
  }
}
.Typewriter--fixed {
  font-size: 2.8rem;
  @apply px-4 md:px-0  sm:text-5xl md:text-7xl lg:text-8xl md:text-left lg:text-center;
}